import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import arrowLeft from 'assets/images/arrow-left.svg';
import closeIcon from 'assets/images/close.svg';
import favIconActive from 'assets/images/favorite-active.svg';
import classnames from 'classnames';
import get from 'lodash/get';
import { setMobileNavContextPath } from 'pdp/pages/ProductPage/actions';
import giftFinderIcon from 'assets/images/gift/Sparkle_GiftFinder_mobile.svg';
import GenderSelect from 'storefront/components/Header/GenderSelect/genderSelect';
import { getSelectedGender } from 'client-utils/utilities-gender';
import { setGender } from 'client/common/components/App/app-actions';
import { handleClearAllFilters, handleURLUpdate } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import { refreshResultsForGenderSwitch } from 'srp/utils/srpUtils';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import { ABTEST_BF_SALE, ABTEST_HN_RIGHT } from 'storefront/components/constants';
import {
  getProfileData, getAvailableStoresInfo, getMappedStoreInfo, getStoreList, filterActiveStores,
} from 'client/common/components/YourNeimans/components/utilities';
import getLinkDomain from 'client/utilities/getlinkDomain';
import AccountMenu from './AccountMenu';
import { fetchSilosForNewMobileNav } from '../actions-navigation';
import {
  loadNextCategory, loadPreviousCategory, loadCategoryMenu, closeMenu, closeMenuNew,
} from './actions';
import './NewMobileNav.scss';
import { MenuItem } from './MenuItem';
import ContextMenu, { constructInitialContextMenuPath } from './ContextMenu';
import { featuredDesigners, featuredDesignersMen, featuredDesignersWomen } from './FeaturedDesignersData';
import { FeaturedDesignersMenuItem } from './FeaturedDesignersMenuItem';

const MY_FAVORITES = 'View Favorites';
const MY_FAVORITES_ID = 'view_favorites';
const DESIGNER_INDEX_CAT_ID = 'cat000730';
const OUR_STORES = 'Our Stores';
const MY_ORDERS = 'My Orders';
const MATCH_WITH_STYLE_ADVISOR = 'Match with a Style Advisor';

const getLink = getLinkDomain();

const Menu = ({
  categories = [],
  selectedSubCategory,
  isSubcatSelected,
  isFeaturedDesignerSelected,
  handleClick,
  loading,
  index,
  isRootMenu,
  isNextClicked,
  isPrevClicked,
  numberOfSlides,
  applyTransition,
  isMobilePhone,
  holdingContextToggle,
  showSignIn = undefined,
  isPLPScrollPositionOn,
  panelToggle = false,
  giftFinderToggle = false,
  holidaySiloToggle = false,
  genderToggle,
  genderSelection,
  isDomestic,
  saleSiloAbTestToggle,
  bfSaleSiloAbTestToggle,
}) => {
  let classStr = 'slide-default';
  if (numberOfSlides > 1 && isNextClicked) {
    if (index === 0) {
      classStr = `slide-default ${(loading) ? 'transition-left' : ''}`;
    }
    if (index === 1) {
      classStr = `slide-default transition-right ${(loading && applyTransition) ? 'transition-default' : ''}`;
    }
  }

  if (numberOfSlides > 1 && isPrevClicked) {
    if (index === 0) {
      classStr = `slide-default ${(loading) ? 'transition-right' : ''}`;
    }
    if (index === 1) {
      classStr = `slide-default transition-left ${(loading && applyTransition) ? 'transition-default' : ''}`;
    }
  }

  const BottomMenu = () => {
    return (
      <Fragment>
        {
          giftFinderToggle
          && (
            <div className="gift-finder-container">
              <a href="/quiz/gift-finder">
                <img src={giftFinderIcon} className="gf-mobile-icon" />
              </a>
            </div>
          )
        }
        {isDomestic && !showSignIn
          && !panelToggle
          && (
            <MenuItem
              id="login_id"
              key="login_id"
              name="Sign In / Register"
              itemIdx={categories.length + 1}
              url={NMConfig.ACCOUNT_PAGE_URL}
              defaultNavOption
              giftFinderToggle={giftFinderToggle}
              holidaySiloToggle={holidaySiloToggle}
            />
          )
        }
        {showSignIn && !panelToggle && (
          <AccountMenu
            key="account-hamburger-menu"
            navLinkClass="mobilenav__profilemenu-link-on-toggle"
          />
        )}
        {isDomestic && (
          <MenuItem
            id={MY_FAVORITES_ID}
            key={MY_FAVORITES_ID}
            url="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1"
            itemIdx={categories.length + 2}
            lastDefaultCategory
            holidaySiloToggle={holidaySiloToggle}
          >
            <img alt="my favorite icon" src={favIconActive} className="favorite-icon" />
            <span>{MY_FAVORITES}</span>
          </MenuItem>
        )}
      </Fragment>
    );
  };

  return (
    <div
      className={classStr}
    >
      <ul className={classnames({
        'indent-right':
          !isFeaturedDesignerSelected
          && !isRootMenu
          && isSubcatSelected
          && selectedSubCategory !== DESIGNER_INDEX_CAT_ID
          && holdingContextToggle,
      })}
      >
        {categories
          && categories.map(({
            name, catmanId, url, children, id, attributes,
          }, index) => {
            const isHiglighted = catmanId === selectedSubCategory
              && selectedSubCategory !== DESIGNER_INDEX_CAT_ID;

            if (catmanId === 'loadNewDI') {
              return (
                <FeaturedDesignersMenuItem
                  id="featuredDesignersMobile"
                  key={catmanId}
                  selected={selectedSubCategory}
                  isSubcatSelected={isSubcatSelected}
                  genderToggle={genderToggle}
                  gender={genderSelection}
                />
              );
            }

            return (
              <MenuItem
                id={id}
                key={id}
                name={name}
                url={url}
                itemIdx={index}
                clickHandler={() => handleClick(index, catmanId, children)}
                numOfSubcategories={children}
                redTextFlag={attributes.redTextFlag}
                textAdornment={attributes.textAdornment}
                isHighlighted={isHiglighted}
                holdingContextToggle={holdingContextToggle}
                isPLPScrollPositionOn={isPLPScrollPositionOn}
                holidaySiloToggle={holidaySiloToggle}
                saleSiloAbTestToggle={saleSiloAbTestToggle}
                bfSaleSiloAbTestToggle={bfSaleSiloAbTestToggle}
              />
            );
          })
        }
        {
          holdingContextToggle
          && isMobilePhone
          && <BottomMenu />
        }
      </ul>
      {!(holdingContextToggle && isMobilePhone)
        && !panelToggle
        && <div className="mobilenav__panel"><AccountMenu key="account-hamburger-menu" /></div>}
    </div>
  );
};

class NewMobileNav extends Component {
  constructor() {
    super();
    const selectedGender = getSelectedGender();

    this.state = {
      initialGender: selectedGender,
      selectedGender,
      storeName: '',
    };

    this.focusedElementBeforeNav = '';
    this.mobileNavCloseButton = null;

    this.setCloseButtonRef = (element) => { this.mobileNavCloseButton = element; };
    this.setMobileNavRef = (element) => { this.mobileNavRef = element; };

    this.focusCloseButton = () => {
      this.focusedElementBeforeNav = document.activeElement;
      if (this.mobileNavCloseButton) this.mobileNavCloseButton.focus();
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.closeMobileNav = this.closeMobileNav.bind(this);
  }

  componentWillMount() {
    if (this.props.visualNavToggle) {
      const contextMenuPath = constructInitialContextMenuPath(
        this.props.silos,
        this.props.navPath,
        this.props.defaultPath
      );

      this.props.holdingContextToggle
        && this.props.setMobileNavContextPath(contextMenuPath);
    }
  }

  componentDidMount() {
    document.body.classList.add('body__scroll-disabled'); // eslint-disable-line compat/compat
    this.focusCloseButton();
    this.getStoreData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedStoreName !== this.props.selectedStoreName) {
      this.setStoreName(this.props.selectedStoreName);
    }
  }

  componentWillUnmount() {
    if (document.body.classList.value === 'body__scroll-disabled') {
      document.body.removeAttribute('class');
    } else {
      document.body.classList.remove('body__scroll-disabled');
    }
  }

  getStoreData = async () => {
    const { selectedStoreName, isNmNewHeader } = this.props;
    const { selectedStore, favoriteStore, cleanZip } = getAvailableStoresInfo();
    const mappedStoreData = await getMappedStoreInfo(selectedStore, favoriteStore, cleanZip);
    const geoLocatedStores = this.props.clientLocation
      && await getStoreList(this.props.clientLocation.zip);
    const filteredStores = geoLocatedStores?.data && filterActiveStores(geoLocatedStores.data);
    const getStoreName = mappedStoreData.selectedStore || mappedStoreData.favoriteStore
      || selectedStoreName
      || (filteredStores && filteredStores[0]?.name);

    if (isNmNewHeader) {
      this.setStoreName(getStoreName);
    }
  };

  setStoreName(storeName) {
    this.setState({ storeName });
  }

  handleStoreClick() {
    window.YourNeimans && window.YourNeimans.setData({
      isOpen: true,
      pageStack: ['storeListPage'],
    });
  }

  next(index) {
    const data = this.props.visibleCategories[0];
    if (data[index].children > 0) {
      this.props.loadNextCategory(index);
      this.mobileNavRef.scrollTo(0, 0);
    }
  }

  previous() {
    if (!this.props.loading) {
      this.props.loadPreviousCategory();
    }
  }

  handleClick(index) {
    if (!this.props.loading) {
      this.next(index);
    }
  }

  redirectToHomePage() {
    const { selectedGender } = this.state;
    document.location.href = selectedGender === 'W' ? '/' : '/mens';
  }

  refreshContent() {
    const {
      initialGender,
      selectedGender,
    } = this.state;

    const {
      router,
      genderToggle,
      pageIdSRP,
      pageIdHOME,
      genderToggleSRP,
    } = this.props;

    if (initialGender !== selectedGender) {
      if ((genderToggle && genderToggleSRP) && pageIdSRP) {
        refreshResultsForGenderSwitch(selectedGender, router);
      }

      if (genderToggle && !pageIdSRP && !pageIdHOME) {
        this.redirectToHomePage();
      }

      if (genderToggle && pageIdHOME) {
        this.redirectToHomePage();
      }
    }
  }

  closeMobileNav() {
    if (this.props.holdingContextToggle) {
      this.props.closeMenuNew();
    } else {
      this.props.closeMenu();
    }

    this.focusedElementBeforeNav.focus();
    this.refreshContent();
  }

  render() {
    const isRootMenu = this.props.path.length === 0;
    const {
      genderToggle,
      genderToggleSRP,
      pageIdSRP,
      genderSelection,
      brand,
      isDomestic,
      saleSiloAbTestToggle,
      bfSaleSiloAbTestToggle,
      isHNRightToggle,
      user,
      isNmNewHeader,
    } = this.props;

    const { storeName } = this.state;
    const { isAuthenticated } = getProfileData();
    const authenticated = user
      && user.securityStatus
      && (user.securityStatus.toLowerCase() === 'authenticated' || user.securityStatus.toLowerCase() === 'anonymous')
      && user.name
      && isAuthenticated;
    const genderExperience = brand !== 'HC' && genderToggle;
    const linkText = genderSelection === 'M' ? 'All Men' : 'All Women';
    const userOrdersPage = authenticated ? 'order-history' : 'guest-order-history';

    return (
      <Fragment>
        <div className={classnames('mobilenav', isHNRightToggle && 'mobilenav-shift-right')} ref={this.setMobileNavRef}>
          {isNmNewHeader && (
            <div className={classnames(
              'mobile-new-header-links'
            )}
            >
              <div role="button">
                <span onClick={this.handleStoreClick}>
                  {storeName || OUR_STORES}
                </span>
              </div>
              {getLink && (
                <div role="button">
                  <span onClick={() => {
                    window.location.href = `${getLink}/${userOrdersPage}`;
                  }}
                  >
                    {MY_ORDERS}
                  </span>
                </div>
              )}
              {getLink && (
                <div className="style-advisor-link" role="button">
                  <span onClick={() => {
                    window.location.href = `${getLink}/c/cat78570743`;
                  }}
                  >
                    {MATCH_WITH_STYLE_ADVISOR}
                  </span>
                </div>
              )}
            </div>
          )}
          <div
            className={classnames({
              mobilenav__header: true,
              'mobilenav__header--context': this.props.holdingContextToggle,
            })}
          >

            {
              brand !== 'HC' && genderExperience
              && (
                <div className="mobilenav__genderselect">
                  <GenderSelect
                    onGenderSelected={(gender) => {
                      this.setState({ selectedGender: gender });
                      this.props.setGender(gender);
                      this.props.fetchSilosForNewMobileNav();
                      if (pageIdSRP && genderToggleSRP) {
                        this.props.handleClearAllFilters();
                      }
                    }}
                    selectedGender={genderSelection}
                  />
                </div>
              )
            }


            {!genderExperience && this.props.holdingContextToggle && this.props.path.length ? (
              <div
                className="mobilenav__backbutton"
                onClick={() => this.props.loadCategoryMenu([])}
              >
                <img alt="" src={arrowLeft} className="arrow--back" />
                Menu
              </div>
            ) : null}

            {/* TODO: NMOWEB-8458 Fix invalid anchor */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="icon icon--close"
              onClick={this.closeMobileNav}
              ref={this.setCloseButtonRef}
              role="button"
              tabIndex="0"
            >
              <img alt="Close Navigation Menu" src={closeIcon} />
            </a>
          </div>

          {
            (genderExperience && this.props.holdingContextToggle && this.props.path.length)
              ? (
                <div
                  className={classnames({
                    mobilenav__header: true,
                    'mobilenav__header--context': this.props.holdingContextToggle,
                  })}
                >

                  <div
                    className="mobilenav__backbutton"
                    onClick={() => this.props.loadCategoryMenu([])}
                  >
                    <img alt="" src={arrowLeft} className="arrow--back" />
                    {linkText}
                  </div>

                </div>
              )
              : null
          }

          {this.props.holdingContextToggle && (
            <div className="mobilenav__context">
              <ContextMenu
                data={this.props.silos}
                path={this.props.path}
                navPath={this.props.navPath}
                clickHandler={this.props.loadCategoryMenu}
                currentCategory={this.props.catId || this.props.catIdFromNavPath}
                isSubcatSelected={this.props.isSubcatSelected}
                isFeaturedDesignerSelected={this.props.isFeaturedDesignerSelected}
              />
            </div>
          )}
          <div
            className={classnames({
              mobilenav__menu: true,
              'mobilenav__menu--context':
                this.props.holdingContextToggle && this.props.path.length > 0,
              'mobilenav__menu--firstlevel':
                !!genderExperience
                && this.props.holdingContextToggle
                && this.props.path.length === 0,
              'mobilenav__menu--item':
                genderExperience && this.props.holdingContextToggle && this.props.path.length === 0,
            })}
          >
            {this.props.visibleCategories.map((selectedCategories, index) => {
              return (
                <Menu
                  key={`cat-${index}`}
                  categories={selectedCategories}
                  selectedSubCategory={this.props.catId || this.props.catIdFromNavPath}
                  isSubcatSelected={this.props.isSubcatSelected}
                  handleClick={this.handleClick}
                  loading={this.props.loading}
                  isNextClicked={this.props.isNextClicked}
                  isPrevClicked={this.props.isPrevClicked}
                  applyTransition={this.props.applyTransition}
                  index={index}
                  numberOfSlides={this.props.visibleCategories.length}
                  isMobilePhone={this.props.isMobilePhone}
                  holdingContextToggle={this.props.holdingContextToggle}
                  showSignIn={this.props.name}
                  isPLPScrollPositionOn={this.props.isPLPScrollPositionOn}
                  isRootMenu={isRootMenu}
                  panelToggle={this.props.panelToggle}
                  giftFinderToggle={this.props.giftFinderToggle}
                  holidaySiloToggle={this.props.holidaySiloToggle}
                  genderToggle={this.props.genderToggle}
                  genderSelection={genderSelection}
                  isDomestic={isDomestic}
                  saleSiloAbTestToggle={saleSiloAbTestToggle}
                  bfSaleSiloAbTestToggle={bfSaleSiloAbTestToggle}
                />
              );
            })}
          </div>
        </div>
        <div
          className={classnames('mobilenav__overlay',
            isHNRightToggle && 'nav-overlay-left')}
          onClick={this.closeMobileNav}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const isSubcatSelected = (categories, catId, catIdFromNavPath) => {
    if (categories !== null) {
      return categories.some(({ catmanId }) => {
        return catmanId === catId || catmanId === catIdFromNavPath;
      });
    }
    return false;
  };

  const isFeaturedDesignerSelected = (featuredDesigners, catId, catIdFromNavPath) => {
    return featuredDesigners.some((featuredDesigner) => {
      return featuredDesigner.catId === catId || featuredDesigner.catId === catIdFromNavPath;
    });
  };

  const getCatIdFromNavPath = (navPath) => {
    if (navPath != null) {
      return navPath.split('_').pop();
    }

    return null;
  };

  const catIdFromNavPath = getCatIdFromNavPath(
    get(state.page.location.query, 'navpath', null)
  );

  const isDomestic = get(state, 'locale.countryCode') === 'US';

  const panelToggle = get(state, 'toggles.YOUR_NEIMANS_PANEL', false);

  const genderToggle = get(state, 'toggles.HP_GENDER', false) && isDomestic;

  const genderSelection = get(state, 'session.dt_gender', getSelectedGender());

  let featuredDesignersList = [];

  if (genderToggle) {
    if (genderSelection === 'M') {
      featuredDesignersList = featuredDesignersMen;
    } else if (genderSelection === 'W') {
      featuredDesignersList = featuredDesignersWomen;
    } else {
      featuredDesignersList = featuredDesigners;
    }
  } else {
    featuredDesignersList = featuredDesigners;
  }
  return {
    applyTransition: state.mobileNav.applyTransition,
    loading: state.mobileNav.loading,
    isNextClicked: state.mobileNav.isNextClicked,
    isPrevClicked: state.mobileNav.isPrevClicked,
    silos: state.mobileNav.silos,
    visibleCategories: state.mobileNav.visibleCategories,
    path: get(state.mobileNav, 'path', []),
    navPath: get(state.page.location.query, 'navpath', null),
    defaultPath: get(state.templates.templateDetails, 'defaultPath', null),
    catId: get(state.templates.templateDetails, 'id', null),
    isMobilePhone: state.device.isMobilePhone,
    holdingContextToggle: state.toggles.HOLDING_CONTEXT,
    visualNavToggle: state.toggles.VISUAL_NAVIGATION,
    name: state.user.name,
    user: state.user,
    pageIdSRP: state.page.pageId === 'PAGE_ID_SRP',
    pageIdHOME: state.page.pageId === 'PAGE_ID_HOME',
    genderToggleSRP: get(state, 'toggles.SRP_GENDER', false),
    isSubcatSelected: isSubcatSelected(
      get(state.mobileNav, 'visibleCategories[0]', null),
      get(state.templates.templateDetails, 'id', null),
      catIdFromNavPath,
    ),
    isFeaturedDesignerSelected: isFeaturedDesignerSelected(
      featuredDesignersList,
      get(state.templates.templateDetails, 'id', null),
      catIdFromNavPath
    ),
    catIdFromNavPath,
    isPLPScrollPositionOn: get(state, 'toggles.PLP_SCROLL_POSITION', false),
    panelToggle: isDomestic && panelToggle,
    giftFinderToggle: get(state, 'toggles.GIFT_FINDER_ICON', false),
    holidaySiloToggle: get(state, 'toggles.GIFT_SILO_IMAGE', false),
    genderToggle,
    genderSelection,
    brand: state.brand_name.env,
    isDomestic,
    saleSiloAbTestToggle: get(state, 'abTestsOpt.nmsa0001.variation', false),
    bfSaleSiloAbTestToggle: get(state, `abTestsOpt.${ABTEST_BF_SALE}.variation`, false),
    isHNRightToggle: get(state, 'toggles.HN_RIGHT', false)
      || get(state, `abTestsOpt.${ABTEST_HN_RIGHT}.variation`, 'a') === 'b',
    isNmNewHeader: get(state, 'toggles.NM_NEW_HEADER', false) && isDomestic,
    stores: get(state, 'facetedLeftNav.stores', []),
    selectedStoreName: get(state, 'facetedLeftNav.selectedStore.name', ''),
    clientLocation: get(state, 'api.requestContext.akamaiEdgescape', {}),
  };
};

const mapDispatchToProps = {
  loadNextCategory,
  loadPreviousCategory,
  closeMenu,
  closeMenuNew,
  loadCategoryMenu,
  setMobileNavContextPath,
  fetchSilosForNewMobileNav,
  setGender: (gender) => setGender(gender, GENDER_PLACEMENT.MOBILE_NAV),
  handleClearAllFilters,
  handleURLUpdate,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewMobileNav));
