import React from 'react';
import property from 'lodash/property';
import parseInt from 'lodash/parseInt';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import flow from 'lodash/flow';
import partialRight from 'lodash/partialRight';
import classnames from 'classnames';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import PriceAdornment from 'shared/components/SalePrice/components/PriceAdornment/priceAdornment';
import './salePrice.scss';

const getMinPriceAdornment = partialRight(minBy, flow(property('price'), parseInt));
const getMaxPriceAdornment = partialRight(maxBy, flow(property('price'), parseInt));

function getPriceAdornments(adornments, isPLP, currencyCode,
  priceAdornmentColor, enhancementToggle, plpSRPPricingToggle, isUIPRICTest) {
  const retailPrice = getMinPriceAdornment(adornments);
  const maxPrice = getMaxPriceAdornment(adornments);
  return (
    <span>
      <PriceAdornment
        price={maxPrice.price}
        currencyCode={currencyCode}
        enhancementToggle={enhancementToggle}
        plpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
      />
      <PriceAdornment
        currentPrice
        label={(isUIPRICTest || plpSRPPricingToggle) && !isPLP ? '' : retailPrice.label}
        color={priceAdornmentColor}
        price={retailPrice.price}
        currencyCode={currencyCode}
        nonStrikeplpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
      />
      {' ' /* Allow line to wrap */}
    </span>
  );
}

function getFlattenedPriceAdornments(adornLabel, original, priceAdornmentColor,
  retailPrice, isPLP, plpSRPPricingToggle, isUIPRICTest) {
  return (
    <span>
      <PriceAdornment
        price={original}
        isPLP={isPLP}
        plpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
      />
      <PriceAdornment
        currentPrice
        label={adornLabel}
        color={priceAdornmentColor}
        price={retailPrice}
        isPLP={isPLP}
        nonStrikeplpSRPPricingToggle={plpSRPPricingToggle}
        isUIPRICTest={isUIPRICTest}
      />
      {' ' /* Allow line to wrap */}
    </span>
  );
}

function getRetailPrice(retailPrice, currencyCode, isPLP,
  plpSRPPricingToggle, isUIPRICTest) {
  return (
    <span className={classnames(
      { 'price-no-promo': (isUIPRICTest || plpSRPPricingToggle) }
    )}
    >
      {isPLP ? retailPrice : renderFormattedPrice(retailPrice, currencyCode)}
    </span>
  );
}

function displayPriceAdornments(
  adornments,
  currencyCode,
  priceAdornmentColor,
  retailPrice,
  enhancementToggle,
  plpSRPPricingToggle,
  isPLP,
  isUIPRICTest
) {
  return (adornments && adornments.length > 1)
    ? getPriceAdornments(adornments, isPLP,
      currencyCode, priceAdornmentColor, enhancementToggle, plpSRPPricingToggle, isUIPRICTest)
    : getRetailPrice(retailPrice,
      currencyCode, isPLP, plpSRPPricingToggle, isUIPRICTest);
}

function displayFlattenedPriceAdornments(original, currencyCode,
  priceAdornmentColor, retailPrice,
  isPLP, adornLabel, enhancementToggle, plpSRPPricingToggle, isUIPRICTest) {
  return original
    ? getFlattenedPriceAdornments(
      adornLabel,
      original,
      priceAdornmentColor,
      retailPrice,
      isPLP,
      plpSRPPricingToggle,
      isUIPRICTest
    )
    : getRetailPrice(retailPrice, currencyCode, isPLP, plpSRPPricingToggle, isUIPRICTest);
}

const SalePrice = ({
  currencyCode,
  retailPrice,
  adornments,
  priceAdornmentColor,
  isPLP,
  original,
  adornLabel,
  enhancementToggle = false,
  plpSRPPricingToggle = false,
  promotionComponent,
  isUIPRICTest,
}) => {
  if (!retailPrice) {
    return false;
  }
  const classStyle = `product-thumbnail__sale-price${(isUIPRICTest || enhancementToggle) ? ' enhanced' : ''}`;
  return (
    <div className={classStyle}>
      {isPLP ? displayFlattenedPriceAdornments(original, currencyCode,
        priceAdornmentColor, retailPrice, isPLP,
        adornLabel, enhancementToggle, plpSRPPricingToggle, isUIPRICTest)
        : displayPriceAdornments(
          adornments,
          currencyCode,
          priceAdornmentColor,
          retailPrice,
          enhancementToggle,
          plpSRPPricingToggle,
          isPLP,
          isUIPRICTest,
        )}
      {promotionComponent && promotionComponent()}
    </div>
  );
};

export default SalePrice;
