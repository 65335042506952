/* eslint-disable max-len */
import React from 'react';
import get from 'lodash/get';
import './serviceComponent.scss';

function ServiceItem(props) {
  const { cmsContent, isMobilePhone, index } = props;
  const {
    desktopIcon, mobileIcon, desktopTitle, mobileTitle, desktopDescription, mobileDescription, ctaText, ctaUrl, linkInNewTab, textJustification, mobileTextJustification,
  } = get(cmsContent, 'fields', {});
  const serviceIcon = isMobilePhone ? ((mobileIcon && mobileIcon[0]?.url) || (desktopIcon && desktopIcon[0]?.url)) : desktopIcon && desktopIcon[0]?.url;
  const description = isMobilePhone ? (mobileDescription || desktopDescription) : desktopDescription;
  const style = {
    textAlign: isMobilePhone ? mobileTextJustification || textJustification : textJustification,
  };
  const renderIcon = () => (
    <div className="layout-center width-80">
      <img src={serviceIcon} alt={`Service Icon${index}`} />
    </div>
  );


  const renderHyperlink = () => (
    (
      <div className="serviceComponent__cta rich-text rich-text--underlined list">
        <p><a href={ctaUrl} target={linkInNewTab ? '_blank' : '_self'} aria-label={ctaText}>{ctaText}</a></p>
      </div>
    )
  );

  return (
    <>
      {ctaText && ctaUrl && (
      <a href={ctaUrl} className="serviceComponent__item" target={linkInNewTab ? '_blank' : '_self'} style={style}>
        {renderIcon()}
        <div className="serviceComponent__title">{isMobilePhone ? mobileTitle || '' : desktopTitle || ''}</div>
        {description && <div className="serviceComponent__desc">{description}</div>}
        {renderHyperlink()}
      </a>
      )
            }
    </>
  );
}

export default ServiceItem;
