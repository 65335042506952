/* eslint import/no-cycle: 0 */
import React from 'react';
import axios from 'axios';
import cookie from 'react-cookie';
import ExpandingCarousel from 'cms/components/expandingCarousel/expandingCarousel';
import Overlap from 'cms/components/Overlap/Overlap';
// eslint-disable-next-line import/no-named-as-default
import AnchorPointsSticky from 'cms/components/anchorPointsSticky/anchorPointsSticky';
import RenderContentItem from 'cms/components/renderContentItem';
import SimpleLocalNav from 'cms/components/SimpleLocalNav/SimpleLocalNav';
import LocalNav from 'cms/components/localNav/localNavWrapper';
import isEmpty from 'lodash/isEmpty';
import { TrackingTagsMaxLength } from 'cms/constants';
import TextMarqueeWrapper from 'cms/components/textMarquee/TextMarquee';

export const MOBILE_DEVICE = 'Mobile';
export const TABLET_DEVICE = 'Tablet';
export const DESKTOP_DEVICE = 'Desktop';
const cdnHosts = global.cloudinary?.cdnHosts || {};

export const getSpacing = (deviceType, spacingValue, layoutWidth = false) => {
  const value = spacingValue || '';

  if (value === '') {
    return '';
  }
  if (deviceType === MOBILE_DEVICE) {
    return layoutWidth ? `${layoutWidth}-${value}-mobile` : `spacing-${value}-mobile`;
  }
  if (deviceType === TABLET_DEVICE) {
    return layoutWidth ? `${layoutWidth}-${value}-tablet` : `spacing-${value}-tablet`;
  }

  return layoutWidth ? `${layoutWidth}-${value}-desktop` : `spacing-${value}-desktop`;
};

const filterLocalCognito = (suffix) => {
  if (typeof localStorage !== 'undefined') {
    const prefix = 'CognitoIdentityServiceProvider';
    return Object.keys(localStorage).filter(
      (item) => item.startsWith(prefix) && item.endsWith(suffix)
    )[0];
  }
  return null;
};

export const submitAttribution = (cvId) => {
  if (typeof (window) === 'undefined') return;

  const cstmr = cookie.load('cstmr');
  const idToken = filterLocalCognito('idToken');
  const reqHeader = {};
  let ucaToken;

  if (idToken) ucaToken = `Bearer ${localStorage[idToken]}`;

  const requestBody = {
    customer_id: cstmr?.customerId,
    opened_at: new Date().valueOf(),
    cv_id: cvId,
    isLoggedin: cstmr?.isLoggedin,
  };
  if (cstmr?.isLoggedin) {
    reqHeader.authorization = ucaToken;
    axios.post(NMConfig.ATTRIBUTION_URL, requestBody, { headers: reqHeader });
  } else {
    axios.post(NMConfig.ATTRIBUTION_URL, requestBody);
  }
};

export const renderMultiDeviceText = (
  Wrapper,
  {
    isMobilePhone, desktopText, tabletText, mobileText, transformText = (text) => text,
  }
) => {
  if (!isMobilePhone && tabletText) {
    return (
      <Wrapper ariaLabel={desktopText}>
        <div className="hide-on-tablet hide-on-mobile">{transformText(desktopText)}</div>
        <div className="hide-on-desktop">{transformText(tabletText)}</div>
      </Wrapper>
    );
  }
  if (isMobilePhone && mobileText) {
    return <Wrapper ariaLabel={mobileText}>{transformText(mobileText)}</Wrapper>;
  }
  if (isMobilePhone && !mobileText && tabletText) {
    return <Wrapper ariaLabel={tabletText}>{transformText(tabletText)}</Wrapper>;
  }
  return <Wrapper ariaLabel={desktopText}>{transformText(desktopText)}</Wrapper>;
};

export const calculateTextOverlayPositionProps = (
  position, horizontalMargin = 0, verticalMargin = 0, isInPixels = false
) => {
  const horizontalMarginValue = horizontalMargin && isInPixels ? `${horizontalMargin}px` : `${horizontalMargin}%`;
  const verticalMarginValue = verticalMargin && isInPixels ? `${verticalMargin}px` : `${verticalMargin}%`;

  switch (position) {
    case 'Top Left':
      return {
        top: verticalMarginValue,
        left: horizontalMarginValue,
      };

    case 'Top Center':
      return {
        top: verticalMarginValue,
        left: '50%',
        transform: 'translate(-50%, 0)',
      };

    case 'Top Right':
      return {
        top: verticalMarginValue,
        right: horizontalMarginValue,
      };

    case 'Middle Left':
      return {
        top: '50%',
        left: horizontalMarginValue,
        transform: 'translate(0, -50%)',
      };

    case 'Middle Center':
      return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };

    case 'Middle Right':
      return {
        top: '50%',
        right: horizontalMarginValue,
        transform: 'translate(0, -50%)',
      };

    case 'Bottom Left':
      return {
        left: horizontalMarginValue,
        bottom: verticalMarginValue,
      };

    case 'Bottom Center':
      return {
        left: '50%',
        bottom: verticalMarginValue,
        transform: 'translate(-50%, 0)',
      };

    case 'Bottom Right':
      return {
        right: horizontalMarginValue,
        bottom: verticalMarginValue,
      };

    default:
      return {};
  }
};

export const getCaptionStyleProps = (
  position, color, horizontalMargin = 0, verticalMargin = 0, isInPixels = false
) => {
  const textAlign = position.split(' ')[1];

  return {
    ...calculateTextOverlayPositionProps(position, horizontalMargin, verticalMargin, isInPixels),
    textAlign,
    color,
  };
};

export const getAdditionalStyles = (cmsContentFields, bgUrl = '') => {
  const stylesObject = {};
  if (cmsContentFields?.backgroundColor) {
    stylesObject.backgroundColor = cmsContentFields.backgroundColor;
  }
  if (cmsContentFields?.padding) {
    stylesObject.padding = `${cmsContentFields.padding}px`;
  }
  if (cmsContentFields?.layoutAsComponent === 'Expanding Carousel') {
    stylesObject.display = 'block';
  }
  if (cmsContentFields?.layoutAsComponent === 'Anchor-Points-sticky') {
    stylesObject.backgroundColor = 'white';
  }
  if (bgUrl) {
    stylesObject.backgroundImage = `url(${bgUrl})`;
    stylesObject.backgroundSize = 'cover';
  }

  return stylesObject;
};

export const getAlignmentClass = (cmsContentFields) => {
  let classStr = '';
  if (cmsContentFields?.verticallyAlign) {
    classStr = `layout-valign-${cmsContentFields.verticallyAlign.toLowerCase()}`;
  }
  return classStr;
};

export const getLayoutAsComponent = (componentType, innerComponents, anchorBackground = '', trackTags, overlapBorderColor, overlapBorderSize, verticalOverlapPercent, horizontalOverlapPercent, buttonStyle, disableClickAnalytics) => {
  switch (componentType) {
    case 'Expanding Carousel':
      return <ExpandingCarousel cmsContentItems={innerComponents} trackTags={trackTags} />;
    case 'Overlap-top':
    case 'Overlap-right':
    case 'Overlap-bottom':
    case 'Overlap-left': {
      return (
        <Overlap
          cmsContentAssets={innerComponents}
          position={componentType}
          borderColor={overlapBorderColor}
          borderSize={overlapBorderSize}
          verticalOverlapPercent={verticalOverlapPercent}
          horizontalOverlapPercent={horizontalOverlapPercent}
        />
      );
    }
    case 'Anchor-Points-sticky':
      return (
        <AnchorPointsSticky
          cmsContentAssets={innerComponents}
          anchorBackground={anchorBackground}
          trackTags={trackTags}
          buttonStyle={buttonStyle}
        />
      );
    case '2Column-sticky-scroll':
      return (
        <div className="component sticky-layout">
          {
            innerComponents.map((component, index) => (
              <div className="component">
                <RenderContentItem cmsContentItem={component} key={index} />
              </div>
            ))
          }
        </div>
      );
    case 'Simple-Local-Nav':
      return (
        <SimpleLocalNav
          cmsContentAssets={innerComponents}
          buttonStyle={buttonStyle}
          disableClickAnalytics={disableClickAnalytics}
        />
      );
    case 'Local Nav':
      return <LocalNav cmsContentAssets={innerComponents} />;
    case 'Text-Marquee':
      return <TextMarqueeWrapper cmsContentAssets={innerComponents} />;
    default:
      return <div />;
  }
};

export const detectGenderRedirectUrl = (
  gender,
  genderToggle,
  pathname,
  locale,
) => {
  const defaultUrl = '/';
  const menUrl = '/mens';
  const MEN = 'M';
  const WOMEN = 'W';

  if (genderToggle) {
    if (pathname === menUrl && gender === WOMEN) {
      return defaultUrl;
    }
    if (pathname === defaultUrl && gender === MEN) {
      return menUrl;
    }
  }

  if (!genderToggle && pathname === menUrl) {
    return defaultUrl;
  }

  if (genderToggle && locale?.countryCode === 'US') {
    if (gender === '' && pathname === menUrl) {
      return defaultUrl;
    }
    if (gender === WOMEN && pathname === menUrl) {
      return defaultUrl;
    }
    if (gender === MEN && pathname === defaultUrl) {
      return menUrl;
    }
  }

  if (locale?.countryCode !== 'US' && genderToggle) {
    if (gender === MEN && pathname === `${locale?.localeUrl}${menUrl}`) {
      return defaultUrl;
    }
  }

  return null;
};

export const getTrackingTags = (tags = []) => {
  if (!tags.length) return {};
  const newObj = {};
  tags.forEach((tag) => {
    const { type, code } = tag.fields;
    if (newObj[type]) {
      newObj[type] += code;
    } else {
      newObj[type] = code;
    }
  });
  return newObj;
};

export const concatTrackTagsObjects = (obj1 = {}, obj2 = {}) => {
  const shallowCopy1 = { ...obj1 };
  const shallowCopy2 = { ...obj2 };

  for (const [key, value] of Object.entries(shallowCopy2)) {
    if (key !== 'Campaign Name' && shallowCopy1[key]) {
      shallowCopy1[key] += value;
      // eslint-disable-next-line no-continue
      continue;
    }

    shallowCopy1[key] = value;
  }

  return shallowCopy1;
};

export const checkForICIDAndAddTags = (url, propsTags, ownTags = [], isModalContent) => {
  if (isModalContent || !url || url.indexOf('entryId') >= 0 || url.indexOf('icid') >= 0 || url.startsWith('#') || (isEmpty(propsTags) && isEmpty(ownTags)) || ((url.indexOf('horchow.com') === -1 && url.indexOf('neimanmarcus.com') === -1) && !url.startsWith('/'))) return url;
  const emptyTrackTagsValues = {
    'Brand + Channel': '',
    '2nd Group': '',
    '3rd Group': '',
    'Campaign Type': '',
    Vendor: '',
    Category: '',
    'Campaign Name': '',
  };
  const tags = concatTrackTagsObjects(propsTags, getTrackingTags(ownTags));
  const mergedObj = concatTrackTagsObjects(emptyTrackTagsValues, tags);

  for (const key of Object.keys(mergedObj)) {
    if (TrackingTagsMaxLength[key]) {
      const diffNumber = Math.abs(TrackingTagsMaxLength[key] - mergedObj[key].length);
      if (mergedObj[key].length < TrackingTagsMaxLength[key]) {
        mergedObj[key] += 'X'.repeat(diffNumber);
      }
      if (mergedObj[key].length > TrackingTagsMaxLength[key]) {
        mergedObj[key] = mergedObj[key].slice(diffNumber);
      }
    }
  }

  // eslint-disable-next-line compat/compat
  const icid = Object.values(mergedObj).join('_');
  const formattedIcid = icid.endsWith('_') ? icid.slice(0, -1) : icid;
  return `${url}${url.indexOf('?') >= 0 ? '&' : '?'}icid=${formattedIcid}`;
};

export const iosBgSizeCoverFix = (imgUrl) => `
  body::after {
    content: "";
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: url(${imgUrl});
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }`;

export const optimizeImageUrl = (imgUrl = '') => {
  if (imgUrl && !imgUrl.endsWith('.gif') && !imgUrl.includes('dpr_')) {
    return imgUrl.replace('/upload/', '/upload/dpr_2.0/');
  }
  return imgUrl;
};

export const getLayoutBackgroundImage = (
  [img] = [],
  cssrgbToggle,
  imageOptimizationToggle,
  isMobilePhone,
) => {
  if (!img?.['secure_url']) return '';
  let url = img.secure_url;

  if (cssrgbToggle) {
    url = url.replace('q_auto', 'cs_srgb,q_auto');
  }

  if (imageOptimizationToggle && isMobilePhone) {
    url = optimizeImageUrl(url);
  }

  return Object.entries(cdnHosts).reduce(
    (backgroundImageUrl, [cloudinaryHost, nmHost]) => backgroundImageUrl
      .replace(cloudinaryHost, nmHost),
    url,
  );
};

export const setNMConnectCookie = (query, expDays = 14) => {
  const TARGET_CHANNEL = 'nmconnect';
  const {
    // eslint-disable-next-line camelcase
    channel, associatePin, osp_lxts = '0', cv_id,
  } = query;
  if (channel === TARGET_CHANNEL && associatePin && osp_lxts) {
    const currentTimeStamp = new Date().getTime();
    const futureTimeValidFormat = new Date(parseInt(osp_lxts, 10)).getTime() > currentTimeStamp;

    // eslint-disable-next-line camelcase
    if (futureTimeValidFormat && !cv_id) {
      const nowPlusDays = new Date(Date.now() + expDays * 24 * 60 * 60 * 1000);
      cookie.save('assocInfo', `${associatePin}:${channel}`, {
        path: '/',
        domain: `${window.location.hostname}`,
        expires: nowPlusDays,
      });
    }
  }
};
