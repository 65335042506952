import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import values from 'lodash/values';
import pullAt from 'lodash/pullAt';
import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';
import classnames from 'classnames';
import { RecentSearchSuggestions } from 'srp/components/atoms/RecentSearchSuggestions/RecentSearchSuggestions';
import { setGenderTA } from 'client/srp/actions/actions';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import { getSelectedGender, getGenderWithOverride } from 'client-utils/utilities-gender';
import { isGenderOnHPandSRP, isTypeAheadOn } from 'srp/utils/srpUtils';
import { updateSearchTermResult } from 'clientHorchow/components/SearchBox/search-actions';
import Link from 'client/components/lib/Link/index';
import {
  DOWN_KEY, UP_KEY, ESC_KEY, TAB_KEY, INVALID_REGEX_PATTERN,
} from '../../utils/HeaderUtils';
import {
  updateSearchTerm,
  setSearchTermValueFromPlaceholder,
  setPlaceholderValue,
  setRecentlySearchedAnalytics,
} from '../search-actions';
import {
  getPlaceholderValue,
  getSearchTerm,
} from '../search-reducers';
import GenderSelectTA from './GenderSelectTA/genderSelectTA';
import { searchFormSubmit } from '../submitHelper';
import './searchBox.scss';
import '../bloomreach.scss';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputIsFocused: false,
      recentSearchHover: false,
      cursorIndex: -1,
      isSuggestionSelected: false,
      isSearchBoxExpanded: false,
      genderSelectHover: false,
      selectedGender: props.dtGender,
      suggestions: [],
      islistItemselected: '',
    };
    this.suggestionsRef = createRef();
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onRecentSearchHover = this.onRecentSearchHover.bind(this);
    this.handleSelectionOnRecentSearch = this.handleSelectionOnRecentSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleAnalytics = this.handleAnalytics.bind(this);
    this.toggleSessionGenderTA = this.toggleSessionGenderTA.bind(this);
    this.onGenderSelectHover = this.onGenderSelectHover.bind(this);
  }

  componentDidMount() {
    const {
      placeholder,
      setRecentlySearchedAnalytics,
      searchSuggestionsToggle,
      isMobilePhone,
      routing,
      pageId,
      isGenderNavOn,
      brand,
    } = this.props;
    try {
      if (searchSuggestionsToggle && !isMobilePhone) {
        const query = routing.query && routing.query.filterOptions
          ? JSON.parse(routing.query.filterOptions)
          : null;
        const queryExists = query ? values(query).some((val) => { return val.length > 0; }) : false;
        const recentSearches = typeof (localStorage) !== 'undefined'
          ? (JSON.parse(localStorage.getItem('SRP_SEARCH_RECENT_KEYWORDS')) || [])
          : false;
        if (recentSearches && recentSearches.length >= 5) {
          recentSearches.splice(5);
        }
        if (placeholder !== '' && placeholder !== null && placeholder !== 'Search') {
          if (recentSearches) {
            const repeatedSearchValue = recentSearches.indexOf(placeholder);
            if (repeatedSearchValue > -1 && pageId.indexOf('SRP') !== -1) {
              if (
                window.utag_data_dt
                && !(window.utag_data_dt.recently_searched !== undefined)
                && !queryExists
              ) {
                if (window.sessionStorage.getItem('recently_searched') === 'true') {
                  setRecentlySearchedAnalytics(true);
                  window.sessionStorage.removeItem('recently_searched');
                }
              } else {
                setRecentlySearchedAnalytics(false);
              }
              pullAt(recentSearches, repeatedSearchValue);
            } else {
              setRecentlySearchedAnalytics(false);
            }
            recentSearches.unshift(placeholder);
          }
        }
        typeof (localStorage) !== 'undefined'
          && saveToLocalStorage('SRP_SEARCH_RECENT_KEYWORDS', JSON.stringify(recentSearches));
      }
    } catch (e) {
      localStorage.removeItem('SRP_SEARCH_RECENT_KEYWORDS');
    }
    window.addEventListener('beforeunload', this.handleAnalytics);
    if (isGenderNavOn) {
      window.sessionStorage.setItem('ta_gender', getSelectedGender());
    }
    if (!isGenderNavOn && brand === 'NM') {
      window.sessionStorage.setItem('ta_gender', getSelectedGender());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.typeaheadPayload !== this.props.typeaheadPayload) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        suggestions: this.props.typeaheadPayload,
        isSuggestionSelected: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleAnalytics);
  }

  onInputFocus(e) {
    e.preventDefault();
    const { handleOnFocus } = this.props;
    const { placeholder, value } = e.target;

    if (placeholder && placeholder !== 'Search' && !value) {
      handleOnFocus(placeholder, value);
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        inputIsFocused: true,
      };
    });
  }

  onRecentSearchHover(hoverStatus) {
    this.setState((prevState) => {
      const resetGenderValue = hoverStatus === false
        ? this.props.dtGender
        : this.props.selectedGender;
      return {
        ...prevState,
        recentSearchHover: hoverStatus,
        selectedGender: resetGenderValue,
      };
    });
  }

  onGenderSelectHover(hoverStatus) {
    this.setState((prevState) => {
      const resetGenderValue = hoverStatus === false
        ? this.props.dtGender
        : this.props.selectedGender;
      return {
        ...prevState,
        genderSelectHover: hoverStatus,
        selectedGender: resetGenderValue,
      };
    });
  }

  onInputBlur() {
    this.props.setGenderTA(this.props.dtGender);
    this.setState((prevState) => {
      return {
        ...prevState,
        inputIsFocused: false,
        selectedGender: this.props.dtGender,
      };
    });
  }

  onSubmitKeyFocus = (evt) => {
    const { recentSearchHover } = this.state;
    if (evt.keyCode === TAB_KEY && recentSearchHover) {
      this.setState({ recentSearchHover: false });
    }
  }

  toggleSessionGenderTA(gender) {
    window.sessionStorage.setItem('ta_gender', gender);
    this.props.setGenderTA(gender, true);
    this.inputRef.focus();
  }

  handleSelectionOnRecentSearch(value) {
    const { handleOnChange, setRecentlySearchedAnalytics } = this.props;
    const thisContext = this;
    if (value) {
      setRecentlySearchedAnalytics(true);
      handleOnChange(value);
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          recentSearchHover: false,
          cursorIndex: 0,
        };
      });
    }
    setTimeout(() => {
      if (thisContext.submitRef) {
        thisContext.submitRef.click();
      }
    }, 200);
  }

  handleChange(e) {
    const
      {
        setPlaceholderValue,
        searchSuggestionsToggle,
        searchTerm,
        brand,
      } = this.props;
    const
      {
        cursorIndex,
        inputIsFocused,
        recentSearchHover,
        suggestions,
      } = this.state;

    const recentSearches = typeof (localStorage) !== 'undefined'
      ? JSON.parse(localStorage.getItem('SRP_SEARCH_RECENT_KEYWORDS'))
      : [];

    // eslint-disable-next-line max-len
    const limit = take(uniqBy(recentSearches?.filter((word) => word), (item) => item?.toLowerCase()), 5);

    if (brand === 'HC') {
      if (e.keyCode === UP_KEY && cursorIndex >= 0) {
        this.setState((prevState) => ({
          cursorIndex: prevState.cursorIndex - 1,
        }));
        if (cursorIndex === 0) {
          this.setState(() => ({
            cursorIndex: -1,
          }));
        }
      } else if (e.keyCode === DOWN_KEY
        && cursorIndex <= suggestions.length - 1) {
        if (cursorIndex === suggestions.length - 1) {
          this.setState(() => ({
            cursorIndex: -1,
          }));
        }
        this.setState((prevState) => ({
          cursorIndex: prevState.cursorIndex + 1,
        }));
      }
    } else if (e.keyCode === UP_KEY && cursorIndex > 0 && searchSuggestionsToggle) {
      if (document
        .getElementsByClassName('brm-autosuggest-menu').length > 0
        && searchSuggestionsToggle
        && (inputIsFocused || recentSearchHover)
        && searchTerm === '') {
        document
          .getElementsByClassName('brm-autosuggest-menu')[0]
          .getElementsByTagName('ul')[0].innerHTML = '';
      }
      this.setState((prevState) => ({
        cursorIndex: prevState.cursorIndex - 1,
      }));
      setPlaceholderValue(limit[cursorIndex - 1]);
    } else if (e.keyCode === DOWN_KEY && limit !== null) {
      if (cursorIndex < limit.length - 1) {
        if (document
          .getElementsByClassName('brm-autosuggest-menu').length > 0
          && searchSuggestionsToggle
          && (inputIsFocused || recentSearchHover)
          && searchTerm === '') {
          document
            .getElementsByClassName('brm-autosuggest-menu')[0]
            .getElementsByTagName('ul')[0].innerHTML = '';
        }
        this.setState((prevState) => ({
          cursorIndex: prevState.cursorIndex + 1,
        }));
        setPlaceholderValue(limit[cursorIndex + 1]);
      }

      if (cursorIndex === limit.length - 1) {
        this.setState(() => ({
          cursorIndex: 0,
        }));
        setPlaceholderValue(limit[0]);
      }
    } else if (e.keyCode === TAB_KEY && limit !== null && cursorIndex < limit.length - 1) {
      if (e.shiftKey && (recentSearchHover || inputIsFocused)) {
        this.setState((prevState) => ({
          ...prevState,
          recentSearchHover: false,
          inputIsFocused: false,
        }));
      } else {
        this.setState(() => ({
          recentSearchHover: true,
        }));
      }
    } else if (e.keyCode === ESC_KEY) {
      this.setState((prevState) => ({
        ...prevState,
        recentSearchHover: false,
        inputIsFocused: false,
      }));
      this.inputRef.blur();
    }
  }

  handleTextChange(e) {
    const {
      handleOnChange, getSearchSuggestions, searchURL, brand,
    } = this.props;
    handleOnChange(e.target.value);
    if (brand === 'HC' && e.target.value !== '') {
      getSearchSuggestions(e.target.value, searchURL);
    }
  }

  handleAnalytics() {
    if (this.props.brand !== 'HC' && this.searchEnterTypeRef.value !== 'bloomreach') {
      const { setRecentlySearchedAnalytics } = this.props;
      const recentSearches = localStorage
        ? JSON.parse(localStorage.getItem('SRP_SEARCH_RECENT_KEYWORDS'))
        : [];
      if (recentSearches.includes(this.inputRef.placeholder)) {
        window.sessionStorage.setItem('recently_searched', true);
      } else {
        setRecentlySearchedAnalytics(false);
      }
    }
  }

  searchSuggestion(suggestion, searchTerm) {
    // Use the replace method to remove invalid characters
    if (searchTerm) {
      let searchTermClone = searchTerm;
      searchTermClone = searchTermClone.replace(INVALID_REGEX_PATTERN, '');
      const parts = suggestion.split(new RegExp(`(${searchTermClone})`, 'gi'));
      return <span>{parts.map((part) => (part.toLowerCase() === searchTermClone.toLowerCase() ? <span className="match">{part}</span> : part))}</span>;
    }
    return <span />;
  }

  render() {
    const {
      searchTerm,
      placeholder,
      searchSuggestionsToggle,
      isGenderNavOn,
      typeAheadExperience,
      noGenderSRPtestIsOn,
      brand,
      isDomestic,
    } = this.props;
    const {
      inputIsFocused,
      recentSearchHover,
      cursorIndex,
      genderSelectHover,
      suggestions,
      isSuggestionSelected,
    } = this.state;
    const genderContainer = (isDomestic && isGenderNavOn && !noGenderSRPtestIsOn) ? 'header-search-box-container-gender' : 'header-search-box-container';
    const brClass = (isDomestic && isGenderNavOn && !noGenderSRPtestIsOn) ? 'br-search-static-gender' : 'br-search-static';
    const transformStyle = (isDomestic && isGenderNavOn && !noGenderSRPtestIsOn) ? { transform: 'translateY(-20px)' } : {};
    const brClassHC = isGenderNavOn ? 'br-search-static set-margin-top' : 'br-search-static';
    const showRecentSearches = searchSuggestionsToggle && searchTerm === '' && (inputIsFocused || recentSearchHover);
    const inputElements = brand === 'HC'
      ? (
        <>
          <input type="hidden" title="req-type" name="request_type" value="search" />
          <input type="hidden" title="responsive" name="responsive" value="true" />
          <input type="hidden" title="search-type" name="search_type" value="keyword" />
          <input type="hidden" title="search-type" name="fl" value="" />
        </>
      )
      : (
        <input
          ref={(searchEnterType) => { this.searchEnterTypeRef = searchEnterType; }}
          type="hidden"
          id="searchEnterType"
          value="bloomreach"
        />
      );

    const suggestionsList = brand === 'HC' && suggestions.length > 0 && searchTerm !== '' ? (
      suggestions.slice(0, 10).map((suggestion, index) => {
        return (
          <li
            key={index}
            className={classnames({ 'hightlight-arrow-updown': cursorIndex === index })}
          >
            <Link
              aria-label={`Shop more: ${suggestion}`}
              target="_self"
              to={{
                pathname: `${'/s/'}`,
                query: {
                  from: 'elSearch',
                  responsive: 'true',
                  request_type: 'search',
                  search_type: 'keyword',
                  q: `${suggestion}`,
                  l: `${suggestion}`,
                  src: 'suggest',
                  dq: `${suggestion}`,
                  aq: `${searchTerm}`,
                  fl: '',
                },
              }}
            >
              {
                this.searchSuggestion(suggestion, searchTerm)
              }
            </Link>
          </li>
        );
      })
    ) : (<li />);
    return (
      <div className={`${genderContainer} desktop-tablet-only`}>
        <form
          className="search-box"
          action="/s/"
          onSubmit={(e) => searchFormSubmit(e, searchTerm)}
          method="GET"
        >
          <div className="input-and-button">
            <div className="search-input-btn-container">
              <div className="search-input-btn">
                <input type="hidden" title="from-input" name="from" value={brand === 'HC' ? 'elSearch' : 'brSearch'} />
                <input type="hidden" title="l-input" name="l" value={searchTerm} />
                <input
                  ref={(inputRef) => { this.inputRef = inputRef; }}
                  type="text"
                  title="search box"
                  autoComplete="off"
                  id={brand === 'HC' ? 'elSearchInput' : 'brSearchInput'}
                  name="q"
                  className="search-box__text"
                  value={searchTerm}
                  aria-label={`Search Box ${showRecentSearches ? 'Expanded' : 'Collapsed'}`}
                  placeholder={placeholder || 'Search'}
                  onFocus={this.onInputFocus}
                  onBlur={this.onInputBlur}
                  onKeyDown={this.handleChange}
                  onChange={this.handleTextChange}
                />
                {inputElements}
                {
                  isDomestic
                  && brand !== 'HC'
                  && isGenderNavOn
                  && !noGenderSRPtestIsOn
                  && (genderSelectHover || searchTerm !== '')
                  && (
                    <div
                      onMouseEnter={() => this.onGenderSelectHover(true)}
                      onMouseLeave={() => this.onGenderSelectHover(false)}
                    >
                      <GenderSelectTA
                        onGenderSelected={(gender) => {
                          this.toggleSessionGenderTA(gender);
                        }}
                        selectedGender={
                          this.props.selectedGender
                            ? this.props.selectedGender
                            : this.state.selectedGender
                        }
                        isTypeAhead={typeAheadExperience}
                      />
                    </div>
                  )
                }
                <div className={`make-relative ${brClass}`} />
                {showRecentSearches
                  && (
                    <div
                      className="recent-search-renderer"
                      onMouseEnter={() => this.onRecentSearchHover(true)}
                      onMouseLeave={() => this.onRecentSearchHover(false)}
                    >
                      <RecentSearchSuggestions
                        closeRecentSearchOnClear={this.onRecentSearchHover}
                        handleSelection={this.handleSelectionOnRecentSearch}
                        arrowIndex={cursorIndex}
                        forwardRef={this.suggestionsRef}
                      />
                    </div>
                  )
                }
              </div>
              <div onKeyUp={(e) => this.onSubmitKeyFocus(e)}>
                <input
                  ref={(submitRef) => { this.submitRef = submitRef; }}
                  type="submit"
                  title="submit button"
                  value="search"
                  className="search-box__submit headerSearchButton"
                  style={transformStyle}
                />
              </div>
            </div>
            <div>
              <div>
                {brand === 'HC'
                  ? (
                    <div className={`make-relative ${brClassHC}`}>
                      {isSuggestionSelected ? (
                        <div className="autosuggest-wrap">
                          <ul className="ui-autocomplete">
                            {suggestionsList}
                          </ul>
                        </div>
                      ) : ''}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';

  return {
    searchTerm: getSearchTerm(state),
    placeholder: getPlaceholderValue(state),
    searchSuggestionsToggle: get(state, 'toggles.RECENTLY_SEARCHED', false),
    routing: get(state, 'routing.locationBeforeTransitions', {}),
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    pageId: get(state, 'page.pageId', ''),
    brand: state.brand_name.env,
    searchURL: get(state, 'hcSearch.searchURL', ''),
    hpGenderToggle: get(state, 'toggles.HP_GENDER', false) && isDomestic,
    selectedGender: get(state, 'srp.search.ta_gender'),
    dtGender: getGenderWithOverride(state),
    isGenderNavOn: isGenderOnHPandSRP(state),
    typeAheadExperience: isTypeAheadOn(state),
    typeaheadPayload: get(state, 'hcSearch.typeaheadPayload', {}),
    isDomestic: get(state, 'locale.countryCode') === 'US',
    noGenderSRPtestIsOn: get(state, 'toggles.GENDERLESS_SRP', false),
  };
};

const mapDispatchToProps = ({
  handleOnChange: updateSearchTerm,
  getSearchSuggestions: updateSearchTermResult,
  handleOnFocus: setSearchTermValueFromPlaceholder,
  setPlaceholderValue,
  setRecentlySearchedAnalytics,
  setGenderTA,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
