import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import hamburger from 'assets/images/hamburger.svg';
import { toggleNavSliderMenu } from '../actions-navigation';
import { getSliderMenuVisible } from '../NavSlider/navSlider-reducer';
import './mobileNav.scss';

const MobileNav = ({ sliderMenuVisible, toggleNavSliderMenu, isHNRightToggle }) => {
  const toggleMenu = () => {
    toggleNavSliderMenu();
  };
  return (
    <div className={classnames('mobile-only mobile-navigation-hamburger-nav-container',
      isHNRightToggle && 'nav-shift-right')}
    >
      { /* TODO: NMOWEB-8454 Fix invalid anchor */ }
      { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
      <a
        aria-expanded={sliderMenuVisible}
        role="button"
        onClick={toggleMenu}
        tabIndex="0"
      >
        <img className="mobile-navigation-hamburger-nav" alt="Navigation Menu" src={String(hamburger)} />
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sliderMenuVisible: getSliderMenuVisible(state),
  isHNRightToggle: get(state, 'toggles.HN_RIGHT', false)
    || get(state, 'abTestsOpt.tl228.variation', 'a') === 'b',
});

const mapDispatchToProps = ({
  toggleNavSliderMenu,
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileNav);
