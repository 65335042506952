import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';
import axios from 'axios';
import { TYPEAHEAD_TEST } from 'shared/actions/actions-page';
import { WOMEN } from 'client/common/constants';
import { getAuthApiData } from '../../../../utilities/amplifyUtils';
import {
  getOfferNotificationCount, fetchPromoCardData, getGiftCardCount, getIncircleNotificationCount,
} from '../../../../common/components/YourNeimans/components/utilities';


export const DOWN_KEY = 40;
export const UP_KEY = 38;
export const ENTER_KEY = 13;
export const ESC_KEY = 27;
export const TAB_KEY = 9;

export const PROMPT_CODES_MAP = {
  P12950WN: {
    message: 'New offer for you! Earn a $125 gift card on men’s',
    analyticsCode: 'SOM-002',
  },
  P12951WN: {
    message: 'New offer for you! Earn a $175 gift card on men’s',
    analyticsCode: 'SOM-003',
  },
  P12952WN: {
    message: 'New offer for you! Earn a $325 gift card on men’s',
    analyticsCode: 'SOM-004',
  },
  P12953WN: {
    message: 'New offer for you! Earn a $150 gift card on shoes & handbags',
    analyticsCode: 'SOM-005',
  },
  P12954WN: {
    message: 'New offer for you! Earn a $175 gift card on shoes & handbags',
    analyticsCode: 'SOM-006',
  },
  P12955WN: {
    message: 'New offer for you! Earn a $450 gift card on shoes & handbags',
    analyticsCode: 'SOM-007',
  },
  P12960WN: {
    message: 'Check out offers just for you!',
    analyticsCode: 'SOM-008',
  },
};
export const PROMPT_CODES_MAP_DEVINT = {
  P17380WN: {
    message: 'New offer for you! Earn a $125 gift card on men’s',
    analyticsCode: 'SOM-002',
  },
  P17381WN: {
    message: 'New offer for you! Earn a $175 gift card on men’s',
    analyticsCode: 'SOM-003',
  },
  P17382WN: {
    message: 'New offer for you! Earn a $325 gift card on men’s',
    analyticsCode: 'SOM-004',
  },
  P17383WN: {
    message: 'New offer for you! Earn a $150 gift card on shoes & handbags',
    analyticsCode: 'SOM-005',
  },
  P17384WN: {
    message: 'New offer for you! Earn a $175 gift card on shoes & handbags',
    analyticsCode: 'SOM-006',
  },
  P17385WN: {
    message: 'New offer for you! Earn a $450 gift card on shoes & handbags',
    analyticsCode: 'SOM-007',
  },
  P17390WN: {
    message: 'Check out offers just for you!',
    analyticsCode: 'SOM-008',
  },
};
export const reloadBrTypeAhead = (newTypeAheadActive) => {
  if (!document.getElementById('searchbox_bloomreach')) {
    const [head] = document.getElementsByTagName('head');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/assets/scripts/searchResponsive_${newTypeAheadActive ? 'typeahead' : 'recentsearch'}.js?v=082024`;
    script.setAttribute('id', 'searchbox_bloomreach');
    head.appendChild(script);
  }
};

export const isTypeaheadOn = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const isABTestTypeaheadOn = get(state, 'toggles.ABTEST_TYPEAHEAD', false);
  const isTypeaheadOn = get(state, 'toggles.TYPEAHEAD', false);
  const isGenderTypeahead = get(state, 'toggles.GENDER_TYPEAHEAD', false);
  const typeaheadAssignment = get(state, `abTestsOpt.${TYPEAHEAD_TEST}.variation`, '');
  return (isTypeaheadOn || (!isGenderTypeahead && isABTestTypeaheadOn && (typeaheadAssignment === 'b'))) && isDomestic;
};

export const getStorageKey = ({ isGenderTypeahead, typeAheadExperience, taGender }) => {
  const baseKeyword = 'SRP_SEARCH_RECENT_KEYWORDS';

  if (typeAheadExperience && isGenderTypeahead) {
    return taGender === WOMEN ? `${baseKeyword}_WOMEN` : `${baseKeyword}_MEN`;
  }

  return baseKeyword;
};

export const getRecentSearch = (recentSearch) => {
  // eslint-disable-next-line max-len
  const limitedSearch = take(uniqBy(recentSearch?.filter((word) => word), (item) => item?.toLowerCase()), 7) || [];
  return limitedSearch;
};

export const getNumPromoCards = async () => {
  const { fastlyHost, profileId, headers } = await getAuthApiData();
  try {
    const giftCardData = await fetchPromoCardData(
      fastlyHost,
      profileId,
      headers
    );
    const promoCardCount = getGiftCardCount(giftCardData, profileId);
    return promoCardCount;
  } catch (error) {
    return 0;
  }
};

const promoMessageForPromt = (offersdata) => {
  let promoPrompt = PROMPT_CODES_MAP.fallBack;
  offersdata?.forEach((offer) => {
    if (!isEmpty(PROMPT_CODES_MAP[(offer?.promoKey)])) {
      promoPrompt = PROMPT_CODES_MAP[(offer?.promoKey)];
    } else if (!isEmpty(PROMPT_CODES_MAP_DEVINT[(offer?.promoKey)])) {
      promoPrompt = PROMPT_CODES_MAP_DEVINT[(offer?.promoKey)];
    }
  });
  return promoPrompt;
};

export const getNumPromos = async () => {
  const {
    fastlyHost, profileId, ucaId, headers,
  } = await getAuthApiData();
  try {
    const { data } = await axios.get(
      `${fastlyHost}/promotions/v1/profiles/${ucaId}/special-offers/cached`,
      { headers }
    );
    const filteredOffers = data.filter((ele) => ele.offerFlag !== 'Evergreen');

    const offersCount = getOfferNotificationCount(filteredOffers, profileId);
    const promptData = promoMessageForPromt(data);
    return { promptData, count: offersCount };
  } catch (error) {
    return 0;
  }
};


export const getNumIncircleCards = async () => {
  const { fastlyHost, profileId, headers: { Authorization } } = await getAuthApiData();
  try {
    const headersUpdated = {
      Authorization,
      'X-NMG-CARD-CLASS': 'ALL',
    };
    const { data } = await axios.get(
      `${fastlyHost}/customer-data/v1/${profileId}/giv-cards`,
      { headers: headersUpdated }
    );
    const incircleCount = getIncircleNotificationCount(data, profileId);
    return incircleCount;
  } catch (error) {
    return 0;
  }
};


export const isSpecialOffersPage = () => window?.location.pathname === '/specialoffers';

export const INVALID_REGEX_PATTERN = /[[\]{}()^|*+?\\]/g;
