/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import cookie from 'react-cookie';
import axios from 'axios';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { referralSourceClicked } from 'shared/actions/actions-page';
import { openProductPanel, getCategoryProducts } from 'cms/actions';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import './stylyzeCarouselComponent.scss';
import { checkForICIDAndAddTags } from '../utils';


export class StylyzeCarouselComponent extends Component {
  constructor(props) {
    super(props);
    this.observer = null;
    this.stylyzeRef = React.createRef();
    this.state = {
      stylyzeProducts: null,
      stylyzeTitle: null,
      stylyzeSubTitle: null,
      isFallback: false,
    };
  }

  async componentDidMount() {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    /* eslint-disable compat/compat */
    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !this.state.isFallback) {
        this.fetchStylyzeProducts();
      }
    }, options);
    this.observer.observe(this.stylyzeRef.current);
  }

  onClickHandler(e, product) {
    e.preventDefault();
    const componentId = get(this.props, 'cmsContentItem.sys.id') || '';
    try {
      referralSourceClicked('Product_Rail', 'product');
      this.props.dispatch(openProductPanel({
        ids: product.id,
        componentId,
        ppOpener: null,
        panelDescription: '',
      }));
    } catch (e) {
      const url = this.state.isFallback ? product.url : product.canonicalUrl;
      window.location.href = url;
    }
  }

    fetchStylyzeProducts = async () => {
      const placement = get(this.props, 'cmsContentItem.fields.placement');
      if (this.observer) this.observer.unobserve(this.stylyzeRef.current);
      const ucaProfileDataCookie = cookie.load('ucaProfileData') || {};
      const cstmr = cookie.load('cstmr') || {};
      /* eslint-disable camelcase */
      const { universal_customer_id, logged_in_status } = ucaProfileDataCookie;
      const groups = cookie.load('_optanalytics') || '';
      const reqParams = {
        channel: 'NMO',
        placement,
        ucaId: universal_customer_id,
        cmdId: cstmr?.cmdId,
        isGuest: !logged_in_status,
        customerId: universal_customer_id,
        groups,
      };
      try {
        const { data } = await axios.get(NMConfig.API_STYLYZE_ABANDON_CART,
          { params: reqParams, timeout: 3000 });
        if (data?.products && data?.products.length) {
          this.setState({ stylyzeTitle: data.stylyze?.masterApiLabel });
          const displayableProducts = data.products.filter((p) => p.displayable);
          if (displayableProducts.length > 4) {
            this.setState({ stylyzeProducts: displayableProducts });
          } else this.fetchCategoryProducts();
        } else {
          this.fetchCategoryProducts();
        }
      } catch (error) {
        this.fetchCategoryProducts();
      }
    };

    UNSAFE_componentWillMount() {
      if (shouldLoad(this.props.cmsCategoryProductsApi)) {
        this.props.dispatch(getCategoryProducts(this.props.categoryId, 10));
      }
    }

    fetchCategoryProducts() {
      const { cmsCategoryProducts } = this.props;
      try {
        const products = cmsCategoryProducts[this.props.categoryId];
        if (products.length) {
          const categoryProducts = products.map((prod) => prod.displayable && prod);
          categoryProducts.splice(10);
          for (let i = 0; i < categoryProducts.length; i++) {
            categoryProducts[i].designer = {};
            categoryProducts[i].details = {};
            categoryProducts[i].media = {
              main: {
                dynamic: {
                  url: categoryProducts[i].imageUrl,
                },
              },
            };
            categoryProducts[i].designer.name = categoryProducts[i].designerName;
            categoryProducts[i].details.canonicalUrl = categoryProducts[i].url;
          }
          this.setState({
            stylyzeProducts: categoryProducts,
            stylyzeTitle: 'Best Sellers',
            stylyzeSubTitle: 'SHOP ALL BEST SELLERS',
            isFallback: true,
          });
        }
      } catch (error) {
        console.error('Unable to fetch category products', error);
      }
    }

    render() {
      const { cmsContentItem, key, trackTags } = this.props;
      const useStylyzeTitle = get(cmsContentItem, 'fields.useStylyzeTitle');
      const cta = get(cmsContentItem, 'fields.cta') || null;
      const bgColor = get(cmsContentItem, 'fields.backgroundColor');
      const ownTrackTags = get(cmsContentItem, 'fields.trackingTags', []);

      const carouselSettings = () => {
        return {
          arrows: true,
          infinite: false,
          slidesToShow: 5,
          slidesToScroll: 5,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                arrows: false,
                infinite: false,
                slidesToShow: 2.5,
                slidesToScroll: 2,
                draggable: true,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                arrows: false,
                infinite: false,
                slidesToShow: 3.5,
                slidesToScroll: 3,
                draggable: true,
              },
            },
            {
              breakpoint: 1600,
              settings: {
                arrows: true,
                slidesToShow: 5,
                slidesToScroll: 5,
              },
            },
          ],
        };
      };

      const displayPrice = (product) => {
        const promotionalDollarOff = product.promotions?.length && product.promotions[0].dollarOff;
        const promotionalPercentOff = product.promotions?.length
                && product.promotions[0].percentOff;
        const itemPrice = product.price?.promotionalPrice;
        const priceAdornments = product.price?.adornments;
        const onSale = product.onSale;
        const retailPrice = product.price?.retailPrice;
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        let formattedRetailPrice = formatter.format(retailPrice);
        let formattedItemPrice = formatter.format(itemPrice);
        if (parseInt(formattedRetailPrice.split('.')[1], 10) === 0) formattedRetailPrice = formattedRetailPrice.split('.')[0];
        if (parseInt(formattedItemPrice.split('.')[1], 10) === 0) formattedItemPrice = formattedItemPrice.split('.')[0];

        /* eslint-disable no-nested-ternary */
        const promoPrices = () => (promotionalDollarOff > 0 && promotionalPercentOff > 0 ? (
                <>
                    {Number(promotionalPercentOff).toFixed()}
                    <span>% Off: </span>
                    <span>$ Off: </span>
                </>
        ) : promotionalDollarOff > 0 ? (
                <>
                    <span>$ Off: </span>
                </>
        ) : promotionalPercentOff > 0 ? (
                <>
                    {Number(promotionalPercentOff).toFixed()}
                    <span>% Off: </span>
                </>
        ) : (<></>));

        const adornmentPrice = () => {
          return priceAdornments.map((priceAdornment, index) => (
                    <div className={`price ${index !== 0 ? 'new' : 'discount'}`}>
                        {parseInt(formatter.format(priceAdornment.price).split('.')[1], 10) === 0 ? formatter.format(priceAdornment.price).split('.')[0] : formatter.format(priceAdornment.price)}
                    </div>
          ));
        };

        if (promotionalDollarOff > 0 || promotionalPercentOff > 0) {
          return (
                    <>
                        <div>
                            {' '}
                            {priceAdornments?.length ? adornmentPrice() : formattedRetailPrice}
                            {' '}
                        </div>
                        <div className="price-discount">
                            <div>
                                {promoPrices()}
                                {' '}
                                {formattedItemPrice}
                            </div>
                        </div>
                    </>
          );
        }

        if (priceAdornments?.length) {
          return adornmentPrice();
        }

        if (!onSale) {
          return (
                    <div className="price">
                        {formattedRetailPrice}
                    </div>
          );
        }

        return <></>;
      };

      const renderProduct = (product, index) => {
        const productImage = product.media?.main?.dynamic?.url;
        const designerName = product.designer?.name;
        const maxChar = this.props.isMobilePhone ? 20 : this.props.isTablet ? 35 : 50;
        const productName = product.name.length > maxChar ? `${product.name.slice(0, maxChar - 3)}...` : product.name;
        const linkTo = checkForICIDAndAddTags(product.details?.canonicalUrl,
          trackTags, ownTrackTags);

        return (
                <div className="cart-item-tile" role="group" key={`tile-${index}`} aria-label={`${index} / ${this.state?.stylyzeProducts?.length}`}>
                    <a href={linkTo} onClick={(e) => this.onClickHandler(e, product)} tabIndex="0" className="cart-item-tile__link">
                        <img className="cart-item-tile__img" src={productImage} alt={productName} />
                    </a>
                    <div className="cart-item-tile__designer">{designerName}</div>
                    <div className="cart-item-tile__product">{productName}</div>
                    <div className="cart-item-tile__price">
                        {displayPrice(product)}
                    </div>
                </div>
        );
      };

      return (
            <div className="stylyze-carousel" style={{ backgroundColor: bgColor }} ref={this.stylyzeRef} key={key}>
                <div className="title">
                    <h5>
                        {useStylyzeTitle || this.state.isFallback
                          ? this.state.stylyzeTitle : cmsContentItem?.fields?.title || ''}
                    </h5>
                    {!useStylyzeTitle && !this.state.isFallback && !cta && <p>{cmsContentItem?.fields?.subTitle || ''}</p>}
                    {!useStylyzeTitle && !this.state.isFallback && cta && <a href={cta} aria-label={`View ${cmsContentItem?.fields?.subTitle}`}>{cmsContentItem?.fields?.subTitle}</a>}
                    {this.state.isFallback && this.state.stylyzeSubTitle && <a href={`/c/${this.props.categoryId}`}>{this.state.stylyzeSubTitle}</a>}
                </div>
                <div
                  className="carousel-section"
                >
                    {/* eslint-disable prefer-spread */}
                    <Slider {...carouselSettings()}>
                        {this.state.stylyzeProducts?.map((product, index) => {
                          return renderProduct(product, index);
                        })}
                        {!this.state.stylyzeProducts && Array.apply(null, Array(5)).map((v, i) => <div class="carousel-section__skeleton" key={i} />)}
                    </Slider>
                </div>
            </div>
      );
    }
}

const mapStateToProps = (state, ownProps) => {
  const gender = getGenderWithOverride(state);
  const categoryId = gender === 'M' ? ownProps.cmsContentItem?.fields?.mensBestSellerCategoryId : ownProps.cmsContentItem?.fields?.womensBestSellerCategoryId;
  return {
    cmsCategoryProducts: state.cms.productsByCategory,
    isMobilePhone: get(state, 'device.isMobilePhone'),
    isTablet: get(state, 'device.isTablet'),
    categoryId,
    cmsCategoryProductsApi: state.api[`cms_currated_products_${categoryId}`.toLowerCase()],
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(StylyzeCarouselComponent);
